import { z } from "zod";

const envClientSchema = z.object({
  /*
	|--------------------------------------------------------------------------
	| App
	|--------------------------------------------------------------------------
	*/

  NEXT_PUBLIC_DEBUG_MODE: z.boolean(),
  NEXT_PUBLIC_NODE_ENV: z.string(),

  /*
	|--------------------------------------------------------------------------
	| Square
	|--------------------------------------------------------------------------
	*/

  NEXT_PUBLIC_SQUARE_BASE_PATH: z.string(),
  NEXT_PUBLIC_MERCHANT_SCOPES: z.array(z.string()),
  NEXT_PUBLIC_SQUARE_APPLICATION_ID: z.string(),

  /*
	|--------------------------------------------------------------------------
	| Sentry
	|--------------------------------------------------------------------------
	*/
  NEXT_PUBLIC_SENTRY_DSN: z.string(),
  /*
	|--------------------------------------------------------------------------
	| Google
	|--------------------------------------------------------------------------
	*/

  GOOGLE_CLIENT_ID: z.string(),

  /*
	|--------------------------------------------------------------------------
	| Clarity
	|--------------------------------------------------------------------------
	*/

  MICROSOFT_CLARITY_ID: z.string(),
  /*
	|--------------------------------------------------------------------------
	| CDN_URL
	|--------------------------------------------------------------------------
	*/

  CDN_URL: z.string(),
  PRIVACY_POLICY_URL: z.string(),
  T_AND_C_URL: z.string(),

  NODE_ENV: z.string(),
  IS_PRODUCTION: z.boolean(),
  STRIPE_PUBLISHABLE_KEY: z.string(),
  SUBSCRIPTION_WAIVER_ID: z.string(),
});

const envClientObj = {
  /*
	|--------------------------------------------------------------------------
	| App
	|--------------------------------------------------------------------------
	*/

  NEXT_PUBLIC_DEBUG_MODE: process.env.DEBUG_MODE === "true",
  NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV ?? "development",

  /*
	|--------------------------------------------------------------------------
	| Square
	|--------------------------------------------------------------------------
	*/
  NEXT_PUBLIC_SQUARE_BASE_PATH:
    process.env.NEXT_PUBLIC_SQUARE_BASE_PATH ?? "https://connect.squareup.com",
  NEXT_PUBLIC_MERCHANT_SCOPES: [
    "ITEMS_READ",
    "ITEMS_WRITE",
    "MERCHANT_PROFILE_READ",
    "PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS",
    "PAYMENTS_WRITE",
    "PAYMENTS_READ",
    "CUSTOMERS_WRITE",
    "CUSTOMERS_READ",
    "GIFTCARDS_WRITE",
    "GIFTCARDS_READ",
    "ORDERS_WRITE",
    "ORDERS_READ",
    "LOYALTY_READ",
    "LOYALTY_WRITE",
    "INVENTORY_READ",
    "SUBSCRIPTIONS_READ",
    "SUBSCRIPTIONS_WRITE",
    "INVOICES_WRITE",
    "INVOICES_READ",
    "APPOINTMENTS_READ",
    "APPOINTMENTS_BUSINESS_SETTINGS_READ",
    "APPOINTMENTS_WRITE",
    "APPOINTMENTS_BUSINESS_SETTINGS_WRITE",
    "APPOINTMENTS_ALL_READ",
    "APPOINTMENTS_ALL_WRITE",
    "EMPLOYEES_READ",
  ],
  NEXT_PUBLIC_SQUARE_APPLICATION_ID:
    process.env.NEXT_PUBLIC_SQUARE_APPLICATION_ID ?? "",
  /*
	|--------------------------------------------------------------------------
	| Sentry
	|--------------------------------------------------------------------------
	*/
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN ?? "",
  /*
	|--------------------------------------------------------------------------
	| Google
	|--------------------------------------------------------------------------
	*/
  GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "",

  /*
	|--------------------------------------------------------------------------
	| Clarity
	|--------------------------------------------------------------------------
	*/
  MICROSOFT_CLARITY_ID: process.env.NEXT_PUBLIC_MICROSOFT_CLARITY ?? "",
  /*
	|--------------------------------------------------------------------------
	| CDN_URL
	|--------------------------------------------------------------------------
	*/
  CDN_URL: "https://cdn.per-diem.co",

  PRIVACY_POLICY_URL:
    process.env.NEXT_PRIVACY_POLICY_URL ??
    "https://www.tryperdiem.com/privacy-policy",
  T_AND_C_URL:
    process.env.T_AND_C_URL ??
    "https://www.tryperdiem.com/terms-and-conditions",

  NODE_ENV: process.env.NODE_ENV ?? "development",
  IS_PRODUCTION: process.env.NODE_ENV === "production",
  STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ?? "",
  SUBSCRIPTION_WAIVER_ID: process.env.NEXT_PUBLIC_SUBSCRIPTION_WAIVER_ID ?? "",
};

export const clientEnv = envClientSchema.parse(envClientObj);
